import React from 'react'
import Layout from '../layouts/simple.js'
import CTA from '../components/cta.js'
import { Helmet } from 'react-helmet'

const Story = () => {
    return (
        <div className="page">
        <Helmet>
            <meta charSet="utf-8" />
            <title>The Dundas Interiors Story - 100 Years of Style and Comfort</title>
            <link rel="canonical" href="dundasinteriors.com" />
        </Helmet>
        <Layout>
            <section className="hero">
                <div className="hero-background">
                    <div className="container">
                        <div className="hero-content">
                            <h1>100 Years Is More Than A Century</h1>
                            <h3>Our people are the reason we've been in business 100 years and we couldn't have done it without them.</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-60" id="story-page-story">
                <div className="container">
                    <div className="column-two">
                        <div>
                            <p>
                                It’s thousands of satisfied customers, hundreds of major projects and innumerable office innovations. And 100 years as a family-owned business has a very personal side too. It’s generations of children making forts out of furniture boxes—both in our clients’ homes and in our own. It’s grandfathers sitting in their grandfathers’ offices showing their grandchildren how the family business operates—again in your offices and in ours. And we have been a part of it since 1921 when Harold and Violet Dundas bought a Helena stationary store on Main Street and expanded into office furnishings. 
                            </p>
                            <p>
                                The years are measured less by calendar time than by era’s of our national culture. The Flapper era when we began, The Big Band Era, Nuclear Era…And when the Franchise Movement of the ‘90’s boomed, so did we, expanding Dundas into places like Boise, Idaho and Reno, Nevada. Yet, as a family business in Montana, the franchise expansion—though profitable--was an imperfect fit for us. So, we sold the outlying businesses to a partner and recommitted--<span className="bold">focused on Montana</span>—our home, our love and our life. 
                            </p>
                            <p>
                                We can never express enough how much your trust has meant to us. Your trust and continued reliance on us for tremendous service and appropriate office and public space design is why we are here telling our centennial story. 
                            </p>
                            <p>
                                We’ve weathered the great depression, World War II, plus many more enjoyable cultural times. Dundas was open for business just up the street from the theater when silent movies wowed the nation. We were watching and listening as “talkies” added sound to movies, and when movies progressed from black & white to Technicolor. And those movies—old and new--now stream to your cell phone. We were already providing office furnishings for decades before the Seattle Kingdome was built…and watched it implode into nothing more than a memory. And now we’re here helping offices run efficiently and safely even in times of pandemic. But all this history isn’t because of us. It’s because of you!
                            </p>
                        </div>
                        <div>
                            <p>    
                                Offering the absolute best in office furnishings and design has helped build your trust. We assist you in the planning stages and stand behind everything we sell. That’s the right way to do business and earn your continued trust.
                            </p>
                            <p>     
                                When a Midwest furniture manufacturer chose the name Herman Miller (two years AFTER Dundas opened for business) it helped us provide you with a level of durability, comfort and style never equaled before or since. Along with the famous and timeless Herman Miller catalog, we’ve always offered the right products for your needs. More than 70 different makers at varying price points, all the while with the Herman Miller backbone of our business.
                            </p>
                            <p> 
                                When Roger Doney and Helene Loy Doney purchased Dundas store in 1965 the tradition of family ownership continued with great customer service, market-topping brands like Herman Miller and a laser focus on Montana and our customers here in our home state. And like the generations of Dundas family members before, the Doney family has continued the tradition for another 55 years. Growing up in furniture box forts, company President, John Doney, began as an employee, earned a partnership in 1999 and took the reins in 2001. Son Conner Doney progressed from employee to partner for our centennial in 2021.
                            </p>
                            <p> 
                                If you have been a customer, client, friend—thank you! Can’t wait to see you again! If you are researching and planning your office space, thanks for finding our website. Browse some of the designs we offer—both modern and classic. Let us apply our century of experience to the specifics of your project. Contact us soon so we can work together!
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <CTA />
        </Layout>
        </div>
    )
}

export default Story